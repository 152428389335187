import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="footer text-white">
                <div className="row mx-5 py-5">
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6 text-left footer-ele ">
                        <img src="images/logo_top.png" alt="" width="150" height="150" style={{ filter: "invert(1)" }} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-6 text-left footer-ele ">

                        <h5><strong>Dr. Hafiz Naeem Asghar</strong></h5>
                        <h5><strong>Director</strong></h5>
                        <h5><strong>Quality Enhancement Cell</strong></h5>
                        <h5><strong>Univeristy of Agriculture Faisalabad</strong></h5>
                        <h5>Off:+92-41-9200161 Ext- 3305</h5>
                        <p>Off:+92-41-9200161 Ext- 3305</p>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12 text-left footer-ele">
                        <h3>Quick links</h3>
                        <a href="synopsis.php"><p className="mt-4">Synopsis Result</p></a>
                        <a href="thesis.php"><p>Thesis Result</p></a>
                  
                        <a href="#"><p>Plagiarism Protocols</p></a>
                        <a href="ratio-load-evalution/index.php?login_type=S" target="_blank"><p>Staff Login</p></a>
                        
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12 text-left footer-ele">
                        <h3>Features</h3>
                        <p className="mt-4">Self Assessment Manual</p>
                        
                        <p>QEC Head Membership</p>
                      
                        <p>Privacy Policy</p>
                        <p>E-Learning</p>
                    </div>

                </div>
            </div>
            <div className="copyportion bg-dark text-white text-center py-4">
                <h6>&copy; All Right reserved.Designed By <a href="#">Danish Enterprises</a></h6>
            </div>
        </>
    )
}

export default Footer