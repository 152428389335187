import React from 'react'
import Header from './inc/Header'
import Footer from './inc/Footer'
import { NavLink, useLocation } from 'react-router-dom'

const Home = () => {
    return (
        <>
            <Header />
            <div className="hero-section"></div>

            <div className="container info-block">
                <div className="row ">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center  block-1">
                        <a href="#"><i className="fa fa-book"></i></a>
                        <h3>VISION</h3>
                        <p>To bring UAF in achieving the status of world class renowned institution for teaching, research & development
                            and innovation to contribute effectively in building-up the required indigenous agriculture sector and allied
                            sciences to achieve sustainable rapid socio-economic development.</p>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center block-2">
                        <a href="#"><i className="fa fa-users"></i></a>
                        <h3>Mission</h3>
                        <p>The mission of the Quality Enhancement Cell, UAF is to improve the standard of education in the areas of
                            agriculture, veterinary sciences, agricultural engineering alongwith allied fields and to integrate the
                            concept of quality assurance in UAF with enhanced levels of international .</p>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center block-3">
                        <a href="#"><i className="fa fa-table"></i></a>
                        <h3>Objective</h3>
                        <p>To build the capacity of UAF to meet the rising global challenges and improve levels of international
                            compatibility and competitiveness of UAF graduates through a systematic capacity building program.</p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <h1>ABOUT QEC UAF</h1>
                        <p>
                            <strong>1-</strong>Quality Enhancement Cell of University of Agriculture, FSD was established in 2009 under the
                            umbrella of Quality
                            Assurance Agency of Higher Education Commission. After completion of project period KMU took over the Quality
                            Enhancement Cell on its regular budget as one of its permanent. <br />
                            <br /><strong>2-</strong> The basic aim of the Quality Enhancement Cell is to improve the standard of education
                            bringing it in line with the
                            standards set for Quality Education at National and International level. The responsibility of QEC is to asses and
                            evaluate the academic policies, Programs and give recommendations accordingly for any improvements if required.
                            <br />
                            <br /><strong>3-</strong>Quality Enhancement Cell of University of Agriculture, FSD is engaged in accomplishments of
                            targets set to create
                            quality culture in the University with the advance practices of quality enhancement. During the period 2011-2021
                            there is an incremental progress in QEC performance as reflected in the graph below
                        </p>
                    </div>

                    <div className="col-md-6 col-12 my-5 p-5 text-center result-form">
                        <div className="bg-light inner">
                            <h3>Synopsis & Thesis Result</h3>
                            <p className="m-4 text-danger"></p>
                            {/* <p className="m-4 text-danger">In Maintenance mode. We are Unable to Provide Online Synopsis & Thesis Results. Kindly Visit QEC Office for Getting Report</p> */}
                            <p className="mt-4 text-center">Click on following link to check your required result</p>
                            <NavLink to="/Synopsis" className="btn btn-primary">Synopsis Result</NavLink>
                            <NavLink to="/Thesis" className="btn btn-primary">Thesis Result</NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Home