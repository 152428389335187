import React from 'react'
import Header from './inc/Header'
import Footer from './inc/Footer'

const Faculties = () => {
    return (
        <>
            <Header />
            <div className="container-fluid bg-dark text-white text-center  py-5">
                <h3>FACULTIES</h3>
                <div className="pt-3 block_text">

                    <a href="index.php">Home</a> &nbsp;/&nbsp; <span>Faculties</span>
                </div>

            </div>


            <div className="container">
                <div className="row">

                    <div className="col-md-6 col-sm-12 col-12">


                        <div className="container-fluid bg-dark text-white text-center  py-4 mt-5">
                            <h3>Faculty Of Agriculture</h3>
                        </div>


                        <div className=" my-4">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item  ">
                                    <h2 className="accordion-header " id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            Agri.Entomology
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body ">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Agronomy
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            CABB
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Forestry Range Management & Wild Life
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Horticulture
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingsix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                            Plant Breeding & Genetics
                                        </button>
                                    </h2>
                                    <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingseven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                            Plant Pathology
                                        </button>
                                    </h2>
                                    <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingeight">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                                            Soil Science
                                        </button>
                                    </h2>
                                    <div id="collapseeight" className="accordion-collapse collapse" aria-labelledby="headingeight"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6 col-sm-12 col-12">

                        <div className="container-fluid bg-dark text-white text-center  py-4 mt-5">
                            <h3>Faculty Of Sciences</h3>
                        </div>
                        <div className=" my-4">
                            <div className="accordion" id="accordionExample">

                                <div className="accordion-item ">
                                    <h2 className="accordion-header " id="heading1">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                            Biochemistry
                                        </button>
                                    </h2>
                                    <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading2">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                            Botany
                                        </button>
                                    </h2>
                                    <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading3">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                            Chemistry
                                        </button>
                                    </h2>
                                    <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading4">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                            Computer Science
                                        </button>
                                    </h2>
                                    <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading5">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                            Humanities & Linguistics
                                        </button>
                                    </h2>
                                    <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading6">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                            Islamiyat
                                        </button>
                                    </h2>
                                    <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading7">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                            Math & Stat
                                        </button>
                                    </h2>
                                    <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading8">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                            Zoology
                                        </button>
                                    </h2>
                                    <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading9">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                            Physics
                                        </button>
                                    </h2>
                                    <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="col-md-6 col-sm-12 col-12">

                        <div className="container-fluid bg-dark text-white text-center  py-4 mt-4">
                            <h3>Animal Husbandry</h3>
                        </div>


                        <div className=" my-4">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item ">
                                    <h2 className="accordion-header " id="heading32">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse32" aria-expanded="false" aria-controls="collapse32">
                                            Animal & Dairy Science
                                        </button>
                                    </h2>
                                    <div id="collapse32" className="accordion-collapse collapse show" aria-labelledby="heading32"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading33">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse33" aria-expanded="false" aria-controls="collapse33">
                                            Animal Husbandry
                                        </button>
                                    </h2>
                                    <div id="collapse33" className="accordion-collapse collapse" aria-labelledby="heading33"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading34">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse34" aria-expanded="false" aria-controls="collapse34">
                                            Animal Nutrition
                                        </button>
                                    </h2>
                                    <div id="collapse34" className="accordion-collapse collapse" aria-labelledby="heading34"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading35">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse35" aria-expanded="false" aria-controls="collapse35">
                                            Livestock
                                        </button>
                                    </h2>
                                    <div id="collapse35" className="accordion-collapse collapse" aria-labelledby="heading35"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading36">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse36" aria-expanded="false" aria-controls="collapse36">
                                            Poultry Science
                                        </button>
                                    </h2>
                                    <div id="collapse36" className="accordion-collapse collapse" aria-labelledby="heading36"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="col-md-6 col-sm-12 col-12">

                        <div className="container-fluid bg-dark text-white text-center  py-4 mt-4">
                            <h3>Faculty Of Agri.Engg & Tech</h3>
                        </div>

                        <div className=" my-4">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item ">
                                    <h2 className="accordion-header " id="heading14">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                            Energy System
                                        </button>
                                    </h2>
                                    <div id="collapse14" className="accordion-collapse collapse show" aria-labelledby="heading14"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading15">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                                            Environmental Engineering
                                        </button>
                                    </h2>
                                    <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading16">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                                            Farm Machinery & Power
                                        </button>
                                    </h2>
                                    <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="heading16"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading17">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                            Fiber Technology
                                        </button>
                                    </h2>
                                    <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="heading17"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading19">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
                                            Irrigation & Drainage
                                        </button>
                                    </h2>
                                    <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="heading19"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading20">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
                                            Structure & Env.Engg
                                        </button>
                                    </h2>
                                    <div id="collapse20" className="accordion-collapse collapse" aria-labelledby="heading20"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>


                    <div className="col-md-6 col-sm-12 col-12">
                        <div className="container-fluid bg-dark text-white text-center  py-4 mt-4">
                            <h3>Faculty Of NIFSAT</h3>
                        </div>
                        <div className=" my-4">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item ">
                                    <h2 className="accordion-header " id="heading21">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                            HND
                                        </button>
                                    </h2>
                                    <div id="collapse21" className="accordion-collapse collapse show" aria-labelledby="heading21"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading22">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                                            Home Sciences
                                        </button>
                                    </h2>
                                    <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="heading22"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading23">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                                            NIFSAT
                                        </button>
                                    </h2>
                                    <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="heading23"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12">
                        <div className="container-fluid bg-dark text-white text-center  py-4 mt-4">
                            <h3>Faculty Of Social Sciences</h3>
                        </div>
                        <div className=" my-4">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item ">
                                    <h2 className="accordion-header " id="heading10">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                            Agri.Extension
                                        </button>
                                    </h2>
                                    <div id="collapse10" className="accordion-collapse collapse show" aria-labelledby="heading10"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading11">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                            IARE
                                        </button>
                                    </h2>
                                    <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading12">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                            IBMS
                                        </button>
                                    </h2>
                                    <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading13">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                            Rural Socialogy
                                        </button>
                                    </h2>
                                    <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 mx-auto">
                        <div className="container-fluid bg-dark text-white text-center  py-4 mt-4">
                            <h3>Vet.Sciences</h3>
                        </div>
                        <div className=" my-4">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item ">
                                    <h2 className="accordion-header " id="heading24">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                                            Anatomy
                                        </button>
                                    </h2>
                                    <div id="collapse24" className="accordion-collapse collapse show" aria-labelledby="heading24"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading25">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapse25">
                                            CMS
                                        </button>
                                    </h2>
                                    <div id="collapse25" className="accordion-collapse collapse" aria-labelledby="heading25"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading26">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse26" aria-expanded="false" aria-controls="collapse26">
                                            EPIDOLOGY
                                        </button>
                                    </h2>
                                    <div id="collapse26" className="accordion-collapse collapse" aria-labelledby="heading26"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading27">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse27" aria-expanded="false" aria-controls="collapse27">
                                            IPPP
                                        </button>
                                    </h2>
                                    <div id="collapse27" className="accordion-collapse collapse" aria-labelledby="heading27"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading28">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapse28">
                                            Microbiology
                                        </button>
                                    </h2>
                                    <div id="collapse28" className="accordion-collapse collapse" aria-labelledby="heading28"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading29">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse29" aria-expanded="false" aria-controls="collapse29">
                                            Parasitology
                                        </button>
                                    </h2>
                                    <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="heading29"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading30">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse30" aria-expanded="false" aria-controls="collapse30">
                                            Pathology
                                        </button>
                                    </h2>
                                    <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="heading30"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="heading31">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse31" aria-expanded="false" aria-controls="collapse31">
                                            Theriogenology
                                        </button>
                                    </h2>
                                    <div id="collapse31" className="accordion-collapse collapse" aria-labelledby="heading31"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            There is no Record/Lecture is uploaded of this Department/Institute.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Faculties