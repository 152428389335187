import React from 'react'
import Header from './inc/Header'
import Footer from './inc/Footer'

const Result = () => {
    return (
        <>
            <Header />
            <div className="container-fluid bg-dark text-white text-center  py-5">
                <h3>SYNOPSIS RESULT</h3>
                <div className="pt-3 block_text">
                    <a href="index.php">Home</a> &nbsp;/&nbsp; <span>Synopsis Result</span>
                </div>
            </div>

            <div className="container mt-5 mb-5">
                <center><h4 style={{ color: "#df5227", fontFamily: "Vendra", fontSize: "25px" }}>Enter Registration Number to Get your Result</h4></center>
                <br /><br />
                <div className="row">
                    <div className="col-md-6">

                        <div className="row">
                            <div className="col-md-5 p-0">

                                <input type="int" name="year" id="year" maxlength="4" className="form-control" style={{ textAlign: "center" }} placeholder="e.g 2022" />
                            </div>
                            <div className="col-md-2">
                                <center>
                                    <span className="text-secondary">-ag-</span>
                                </center>
                            </div>
                            <div className="col-md-5 p-0">

                                <input type="int" minlength="1" maxlength="5" name="reg" id="reg" className="form-control" style={{ textAlign: "center" }} placeholder="e.g 12345" />
                            </div>
                        </div>
                        <center>
                            <b id="dd" style={{ color: "red" }}></b>
                        </center>
                    </div>
                    <div className="col-md-4">
                        <button className="btn btn-primary ml-1" id="get_result" name="get_result">Get Result</button>
                        <button className="btn btn-primary ml-1" id="download" onclick="pdf();">PDF Download</button>
                    </div>
                </div>
                <br />
                <div id="shh">
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-3">
                                <img src="images/result_logo.jpeg" width="60%" height="60%" />
                            </div>
                            <div className="col-md-9">
                                <span style={{ fontSize: "20px" }}>Quality Enhancement Cell</span><br />
                                <span style={{ fontSize: "30px" }}><b>University of Agriculture, Faisalabad</b></span><br />
                                <span>Phone:  92 (41) 9200161-170/ Ext. 3305; Email: directorqec@uaf.edu.pk</span>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <span><b>Registration No:</b> </span><br />
                                <span>
                                    <b>Department:</b>
                                </span><br />
                                <span>
                                    <b>Degree:</b>
                                    M.Sc (Hons.)
                                    M.Phil / MS
                                    Ph.D
                                </span>
                            </div>
                            <div className="col-md-6">
                                <span style={{ float: "right" }}>
                                    The Director, Graduate Studies,
                                    The Controller Examinations,
                                    <br />
                                    University of Agriculture, <br />
                                    Faisalabad.
                                </span>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">

                                <h5>Subject: SYNOPSIS PLAGIARISM CHECKING REPORTS</h5>

                                <h5>Subject: THESIS PLAGIARISM CHECKING REPORTS</h5>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">

                                <span>Here are the reports of Synopsis checked for plagiarism by QEC.</span>

                                <span>Here are the reports of Thesis checked for plagiarism by QEC.</span>

                            </div>
                        </div>
                        <div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ width: "20%" }}>Date</th>
                                        <th style={{ width: "20%" }}>Report No</th>
                                        <th style={{ width: "20%" }}>Result</th>
                                        <th style={{ width: "20%" }}>Attempt</th>
                                        <th style={{ width: "20%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br /><br />
                            <span style={{ color: "red" }}>Note! Errors and Omissions are accepted</span>
                        </div>
                    </div>
                </div>
            </div>



            <Footer />
        </>
    )
}

export default Result