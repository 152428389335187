import React, { useState } from 'react'
import Header from './inc/Header'
import Footer from './inc/Footer'

const Contact = () => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [subject, setSubject] = useState("")
    const [msg, setMsg] = useState("")
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(false)

    async function sendMsg() {
        setLoader(true)
        const formData = new FormData
        formData.append('name', name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('subject', subject)
        formData.append('msg', msg)

        let result = await fetch(window.api + "contactMassage", {
            method: 'POST',
            body: formData
        })

        result = await result.json()

        if (result.success) {
            setLoader(false)
            setSuccess(true)
            setError(false)
            setName(""); setEmail(""); setPhone(""); setSubject(""); setMsg("");
        } else if (result.error) {
            setLoader(false)
            setError(true)
            setSuccess(false)
        }
    }

    return (
        <>
            <Header />
            <div className="container-fluid bg-dark text-white text-center  py-5">
                <h3>GET IN TOUCH</h3>
                <div className="pt-3 block_text">
                    <a href="index.php">Home</a> &nbsp;/&nbsp; <span>Contact Us</span>
                </div>
            </div>
            <div className="container p-4">
                <p className="text-secondary text-center">Use below to reach out to Quality Enhancement Cell, UAF.</p>
                <p className="text-secondary text-center">To receive general information relevant to our office, please fill out the
                    form. You will be contacted by a member of our staff who will give you all the information you need.</p>
                <b></b>
                
                {success ?
                    <span class="badge text-success">Massage Sent Successfully</span> :
                    <></>}
                {error ?
                    <span class="badge text-danger">Massage Not Sent</span> :
                    <></>}
                <div className="row">
                    <div className="col-md-6 col-12">
                   
                        <form action="" role="form" method="post">
                            <div className="mb-3 form">
                                <label for="exampleFormControlInput1" className="form-label fw-bold">Name <sup
                                    className="text-danger">*</sup></label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 form">
                                <label for="exampleFormControlInput1" className="form-label fw-bold">Email <sup
                                    className="text-danger">*</sup></label>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="mb-3 form">
                                <label for="exampleFormControlInput1" className="form-label fw-bold">Phone <sup
                                    className="text-danger">*</sup></label>
                                <input
                                    type="number"
                                    name="mobile"
                                    className="form-control"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="mb-3 form">
                                <label for="exampleFormControlInput1" className="form-label fw-bold">Subject <sup
                                    className="text-danger">*</sup></label>
                                <input
                                    type="text"
                                    name="subject"
                                    className="form-control"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)} />
                            </div>

                            <div className="mb-3 form">
                                <label for="exampleFormControlTextarea1" className="form-label fw-bold">Message <sup
                                    className="text-danger">*</sup></label>
                                <textarea
                                    className="form-control"
                                    name="message"
                                    value={msg}
                                    onChange={(e) => setMsg(e.target.value)} />
                            </div>
                            <div className="form ">
                                <button
                                    type="button"
                                    name="save_contact"
                                    className="btn btn-primary btn-form text-dark"
                                    disabled={!name || !email || !phone || !subject || !msg}
                                    onClick={sendMsg}
                                >
                                    {loader ? "Sending.." : "Send Message"}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 col-12 form-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.5048532194483!2d73.07360931462848!3d31.4277655588546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3922421d9937f131%3A0x129b74d67c73a16d!2sUniversity%20of%20Agriculture%20-%20UAF!5e0!3m2!1sen!2s!4v1659070745670!5m2!1sen!2s"
                            width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact