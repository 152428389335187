import React, { useState, useEffect } from 'react'
import Header from './inc/Header'
import Footer from './inc/Footer'
import { PDFExport } from "@progress/kendo-react-pdf";

const Synopsis = () => {

    const [year, setYear] = useState("")
    const [reg, setReg] = useState("")
    const [buttonText, setButtonText] = useState("Get Result")
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [plagResult, setPlagResult] = useState([])
    const [student, setStudent] = useState("")

    async function getResult() {

        setButtonText("Getting.....")
        const formData = new FormData()
        formData.append('year', year)
        formData.append('reg', reg)
        formData.append('type', 'S')

        let result = await fetch(window.api + "getPlagrism", {
            method: 'POST',
            body: formData
        });
        result = await result.json()
        if (result.error) {
            setButtonText("Get Result")
            setError(true)

        } else {
            setButtonText("Get Result")
            setError(false)
            setPlagResult(result.plagrism)
            setStudent(result.student)
        }

    }

    const pdfExportComponent = React.useRef(null);

    return (
        <>
            <Header />
            <div className="container-fluid bg-dark text-white text-center  py-5">
                <h3>SYNOPSIS RESULT</h3>
                <div className="pt-3 block_text">
                    <a href="#">Home</a> &nbsp;/&nbsp; <span>Synopsis Result</span>
                </div>
            </div>
            <div className="container mt-5 mb-5">
                <center><h4 className='text-secondary'>Enter Registration Number to Get your Result</h4></center>
                <br /><br />
                <div className="row">
                    <div className="col-md-2 p-0">
                        <input
                            type="number"
                            maxlength="4"
                            className="form-control"
                            style={{ textAlign: "center" }}
                            placeholder="e.g 2022"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                        />
                    </div>
                    <div className="col-md-1">
                        <center>
                            <span className="text-secondary">-ag-</span>
                        </center>
                    </div>
                    <div className="col-md-2 p-0">
                        <input
                            type="number"
                            minlength="1"
                            maxlength="5"
                            className="form-control"
                            style={{ textAlign: "center" }}
                            placeholder="e.g 12345"
                            value={reg}
                            onChange={(e) => setReg(e.target.value)}
                        />
                    </div>
                    <div className="col-md-2">
                        <button type='button' onClick={getResult} className="btn btn-primary ml-1" disabled={!year || !reg}>{buttonText}</button>
                    </div>
                    <div className='col-md-2'>

                    </div>
                </div>

                <br />
                {(error) ?
                    <>
                        <p className='text-danger'><b>Dear Student:</b></p>
                        <ul>
                            <li>First Download QEC UAF App from Google Play Store and register yourself.</li>
                            <li>After Registration on QEC UAF APP returned back on this website and get your report please.</li>
                        </ul>
                    </> :
                    <>
                        {Object.keys(plagResult).length > 0 ?
                            <>
                                <button
                                    className="btn btn-info"
                                    onClick={() => {
                                        if (pdfExportComponent.current) {
                                            pdfExportComponent.current.save();
                                        }
                                    }}
                                >
                                    Export PDF
                                </button>
                                <PDFExport paperSize="A3" margin="0.5cm" landscape="true" ref={pdfExportComponent}>
                                    <div className='container border'>
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <img src="images/result_logo.jpeg" width="35%" />
                                            </div>
                                            <div className="col-md-9">
                                                <span style={{ fontSize: "20px" }}>Quality Enhancement Cell</span><br />
                                                <span style={{ fontSize: "30px" }}><b>University of Agriculture, Faisalabad</b></span><br />
                                                <span>Phone:  92 (41) 9200161-170/ Ext. 3305; Email: directorqec@uaf.edu.pk</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-6">
                                                {/* <span><b>Name:</b> {student.name}</span><br /> */}
                                                <span><b>Registration No:</b> {student.Reg_Year}-ag-{student.Reg_Number_Only}</span><br />
                                                <span><b>Department:</b>  {student.Dept_Name}</span><br />

                                            </div>
                                            <div className="col-md-6">
                                                <span style={{ float: "right" }}>
                                                    The Director, Graduate Studies,
                                                    <br />
                                                    University of Agriculture, <br />
                                                    Faisalabad.
                                                </span>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5>Subject: SYNOPSIS PLAGIARISM CHECKING REPORTS</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span>Here is the Similarity Index report of Synopsis generated by QEC - UAF.</span>
                                            </div>
                                        </div>
                                        <div>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Report No</th>
                                                        <th>Result</th>
                                                        <th>Attempt</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {plagResult.map((p, index) => (
                                                        <tr key={index}>
                                                            <td>{p.DDate.substring(0, 10)}</td>
                                                            <td>{p.Report_No}</td>
                                                            <td>{p.Result} %</td>
                                                            <td>{(p.Attempts === 'I') ? "1st" : (p.Attempts === 'II') ? "2nd" : (p.Attempts === 'III') ? "3rd" : ""}</td>
                                                            <td>{(p.Result > 19) ? "Fail" : (p.Single_Source == 1) ? "Fail" : "Pass"} ({p.name}) {(p.Single_Source == 1 ? <i className='text-danger'>Single Source Greater Than 5 %</i>:<></>)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <br />
                                            <span style={{ color: "red" }}>Note!</span>
                                            <ul>
                                                <li>Errors and Omissions are accepted.</li>
                                                <li>This is computer generated report and needs no Signatures.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </PDFExport>
                            </> : <div className="text-center"><i className="text-danger">No Record found</i></div>}
                    </>}

            </div>
            <Footer />
        </>
    )
}

export default Synopsis