import React from 'react'
import Header from './inc/Header'
import Footer from './inc/Footer'

const DMassage = () => {
    return (
        <>
            <Header />
            <div className="container-fluid bg-dark text-white text-center  py-5">
                <h3>DIRECTOR MESSAGE</h3>
                <div className="pt-3 block_text">

                    <a href="index.php">Home</a> &nbsp;/&nbsp; <span>DIRECTOR MESSAGE</span>
                </div>

            </div>

            <div className="message">

                <div className="container py-4 ">
                    <h1 className="text-dark py-5">Director Message</h1>

                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12 ">
                            <div className="image">

                                <img src="images/naeem.jpg" alt="Not found" />
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-6 col-sm-12 col-12 ">
                            <p className="text-secondary para">
                                We are fully committed to implement QAA policies here in our university by establishing a formal
                                Quality Enhancement Cell. Our university is one of the 10 universities where QEC has been
                                established by HEC. We strongly adhere to the concept of Quality Assurance in institutions of
                                higher
                                learning with enhanced levels of International compatibility through capacity building. I am
                                confident that QEC will work hard to improve the quality of higher education being imparted in
                                University of Agriculture, Faisalabad and to bring it at par with most of the international
                                professional universities.The QEC at University of Agriculture, Faisalabad is strongly committed
                                to
                                implement the guidelines and policies of Quality Assurance Agency Higher Commission Education
                                (HEC)
                                in its true spirit. The Higher Education Institutions in Pakistan are facing great challenges
                                especially in comparison with international standards. Our QEC- UAF Mission Statement and
                                Objectives
                                reflect the adoption of quality enhancement culture at University of Agriculture, Faisalabad.
                            </p>
                        </div>
                        <div className="col-sm-12 col-12 para">
                            <p className="text-secondary">The worthy Vice Chancellor Prof. Dr. Iqrar Ahmad Khan(HI, SI) is strongly committed to improve
                                the
                                overall quality standard at University of Agriculture, Faisalabad. His personal interest in
                                facilitating the QEC - UAF is a significant step in promoting quality education in the country.
                                The
                                QEC-UAF will deliver quality and standardized education to produce competitive graduates in all
                                disciplines. Quality Enhancement Cell, University of Agriculture, Faisalabad (QEC-UAF)
                                University of
                                Agriculture, Faisalabad has managed to have remarkable achievements at national & international
                                level and the establishment of QEC under the guidelines of HEC is one of them. The QEC-UAF was
                                established in 2005. The standard of education in Pakistan is not up to the mark of
                                international
                                standards, as our degree is either not accepted or rarely recognized as compared to other
                                countries
                                degree. To fill this gap and to enhance the overall quality of our education system, HEC has set
                                up
                                Quality assurance agency. University of Agriculture, Faisalabad contribute towards overall
                                improvement of education system in the country. The QEC-UAF is involved in surveys related to
                                students, staff and different departments, capacity building of faculty and staff and the
                                facilitating the Self Assessment Process</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default DMassage