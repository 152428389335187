import { Route, Routes, BrowserRouter, HashRouter } from "react-router-dom"
import Home from "./components/Home"
import DMassage from "./components/DMassage"
import Faculties from "./components/Faculties"
import Events from "./components/Events"
import Contact from "./components/Contact"
import Result from "./components/Result"
import PrivacyPolicy from "./components/PrivacyPolicy"
import Thesis from "./components/Thesis"
import Synopsis from "./components/Synopsis"


function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route index exact path="/" element={<Home />} />
          <Route path="/DMassage" element={<DMassage />} />
          <Route path="/Faculties" element={<Faculties />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Result" element={<Result />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/Thesis" element={<Thesis />} />
          <Route path="/Synopsis" element={<Synopsis />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App
