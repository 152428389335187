import React from 'react'
import Header from './inc/Header'
import Footer from './inc/Footer'

const Events = () => {
    return (
        <>
            <Header />
            <div className="event-hero-section">

            </div>


            <div className="container-fluid gallery">
                <h1 className="text-center my-5 ">Gallery</h1>

                <div className="container">
                    <div className="gallery-grid grid grid-gap-sm">
                        <a className="" href="images/w1.jpg" data-lightbox="roadtrip">
                            <img src="images/w1.jpg" />
                        </a>
                        <a className="" href="images/w2.jpg" data-lightbox="roadtrip">
                            <img src="images/w2.jpg" />
                        </a>
                        <a className="" href="images/w3.jpg" data-lightbox="roadtrip">
                            <img src="images/w3.jpg" />
                        </a>
                        <a className="" href="images/w4.jpg" data-lightbox="roadtrip">
                            <img src="images/w4.jpg" />
                        </a>
                        <a className="" href="images/w9.jpg" data-lightbox="roadtrip">
                            <img src="images/w9.jpg" />
                        </a>
                        <a className="" href="images/w6.jpg" data-lightbox="roadtrip">
                            <img src="images/w6.jpg" />
                        </a>
                        <a className="" href="images/w7.jpg" data-lightbox="roadtrip">
                            <img src="images/w7.jpg" />
                        </a>



                    </div>
                </div>
                <div className="d-flex justify-content-center">

                    <button className="btn text-center my-5 contact-btn btn-secondary ">VIEW MORE</button>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default Events